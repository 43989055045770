import {Component} from 'react';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import getCurrentDateTime from '../../objects/CommonUse/get_current_date_time';
import AddAccountModal from './add_account_modal';
import ChangeAccountModal from './change_account_modal';
import AccountUpdateEmailText from './text/account_update_email_text';
import EventTrackerDeleteModal from "./event_tracker_delete_modal";
import GameInfoArchiveModal from './game_info_archive_modal';
//import SendEmail from '../../objects/CommonUse/send_email-save';
//import ConfigFile from '../../datasets/config_file';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';
import WaitTimeSpinner from '../shared-components/wait_time_spinner';
import UpdateEventMoodPoints from './update_event_mood_points';

class OtherUpdates extends Component {
    constructor(props) {
        super(props);
        this.adminInfo = this.props.adminInfo();
        this.state={
          headers: setHeadersForFetch(),
          openAddAccountModal: true,
          openChangeAccountModal: true,
          accountsLoaded: false,
          buttonSelected: null,
          gamesLoaded: false,
          savingsLoaded: false,
          creditCardLoaded: false,
          funStuffLoaded: false,
          activityTrackerLoaded: false,
          updateTestDatasetLoaded: false,
          eventsLoaded: false,
          openMoodPointUpdate: false,
          sendEmail: false,
          openEventTrackerDeleteModal: false,
          openGameArchiveModal: false,
          subProcess: "OtherUpdates",
          environment: Config.dataURL.currentEnv,
        };   
        this.accountObj = null;
    }

    setButtonSelected(buttonName){
        switch (buttonName){
            case "NewAccount":
                this.getAccountList();
                break;
            case "ChangeAccount":
                this.getAccountList();
                break;
            case "DeleteEventTracker":
                this.setState({openEventTrackerDeleteModal: true});
                break;
            case "ArchiveGame":
                this.loadAllGameData();
                this.setState({openGameArchiveModal: true});
                break;
            case "UpdateEventMoodPoints":
                this.loadUpdateTestDataset();
                this.loadEventsForCategory();
                this.setState({openMoodPointUpdate: true});
                break;
            default:
                break;
        }
        this.setState({sendEmail: false});
        this.setState({buttonSelected: buttonName});
    }
   
    closeAddAccountModal(accountObj) {
        if (accountObj !==  undefined){
            this.setState({sendEmail: true});
            this.accountObj = accountObj;
        }else{
            this.setState({buttonSelected: ""});
        }
    }
   
    closeChangeAccountModal(accountObj) {
        if (accountObj !==  undefined){
            this.setState({sendEmail: true});
            this.accountObj = accountObj;
        }else{
            this.setState({buttonSelected: ""});
        }
    }

    sendEmailToAccount(){
        let textType = this.state.buttonSelected;
        if (this.state.buttonSelected === "ChangeAccount"){
            if (+this.accountObj.nbrPrimary > 0){
                textType = textType + "Primary";
            }
            if (+this.accountObj.nbrPlayers > 0){
                textType = textType + "Players";
            }
        }
        let accountMsgObj = AccountUpdateEmailText.find(elem => elem.rqstType === textType);
        let message = accountMsgObj.text;
        this.replaceTextWithVariables(this.accountObj, message);
        let userName = this.accountObj.emailAddress;
        this.emailParms={from_name: "MoneyTrain Game", to_name: userName, userName: userName,
              message: this.message, reply_to: this.accountObj.emailAddress, subject: "Account Setup"};
     //   this.pageStatus = "sendEmail";
     //   let serviceID = ConfigFile.login.accountServiceID;
     //   let publicID = ConfigFile.login.accountPublicID;
     //   let templateName = ConfigFile.login.accountTemplate;
     //   SendEmail(this.emailParms, templateName, serviceID, publicID);
        this.setState({sendEmail: false});
        this.setState({buttonSelected: ""});
    }

    replaceTextWithVariables(accountObj, message){ 
        this.message = message;
        this.message = this.message.replace("||accountNbr||", accountObj.accountNbr);
        this.message = this.message.replace("||primaryRole||", accountObj.primaryRole);
        if (accountObj.primaryAuthCodes !==  undefined){
            let authCodeString = accountObj.primaryAuthCodes.join();
            this.message = this.message.replace("||primaryAuthCodes||", authCodeString);
        }
        this.message = this.message.replace("||nbrPrimary||", accountObj.nbrPrimary);
        this.message = this.message.replace("||nbrPlayers||", accountObj.nbrPlayers);
        this.message = this.message.replace("||spaces||", "        ");
    }
    
    closeModals(modalName){
        switch (modalName){
            case "EventTracker":
                this.setState({openEventTrackerDeleteModal: false});
                break;
            case "ArchiveGame":
                this.allGameDataLoaded = false;
                this.setState({openGameArchiveModal: false});
                break;
            case "UpdateEventMoodPoints":
                this.setState({openMoodPointUpdate: false});
                break;
            default:
                break;
        }
    }
                 
    render() {
            if (this.state.gamesLoaded === true && this.state.savingsLoaded === true &&
              this.state.creditCardLoaded === true && this.state.funStuffLoaded === true &&
              this.state.activityTrackerLoaded === true){
                this.allGameDataLoaded = true;
           }
        
    return <>
    
            <div className="col-sm-6 mb-5 input-page-base-card">
                <div className="card mt-3 mr-5 rounded-corners-lg height-max">
                    <h1 className="mt-2 p-0 text-center">Game Administration</h1>
                    <h4 className="text-center">Other Admin Updates for MoneyTrain Game</h4>
                    <div className="mt-0 mb-0 overflow-scroll">
                        <div className="text-center mt-5">
                            <button className="btn btn-primary w-50 mt-6" 
                                    onClick ={()=> this.setButtonSelected("NewAccount")}>
                                         Add New Account
                            </button>
                        </div>
                        <div className="text-center mt-3">
                            <button className="btn btn-primary w-50 mt-6" 
                                    onClick ={()=> this.setButtonSelected("ChangeAccount")}>
                                         Change Account Information
                            </button>
                        </div>
                        <div className="text-center mt-3">
                            <button className="btn btn-primary w-50 mt-6" 
                                    onClick ={()=> this.setButtonSelected("DeleteEventTracker")}>
                                         Delete Event Tracker Information
                            </button>
                        </div>
                        <div className="text-center mt-3">
                            <button className="btn btn-primary w-50 mt-6" 
                                    onClick ={()=> this.setButtonSelected("ArchiveGame")}>
                                        Archive Old Games and Orphaned Records
                            </button>
                        </div>
                        <div className="text-center mt-3">
                            <button className="btn btn-primary w-50 mt-6" 
                                    onClick ={()=> this.setButtonSelected("UpdateEventMoodPoints")}>
                                        Update Event Mood Points
                            </button>
                        </div>
                      

                    {!this.state.sendEmail ?
                        <span className="mt-4">
                        {this.state.buttonSelected === "NewAccount" && this.state.accountsLoaded ?
                            <AddAccountModal openModal={this.state.openAddAccountModal} 
                                accountList={this.accountInfo}
                                onClose={(accountObj) => this.closeAddAccountModal(accountObj)}
                            />
                        :
                            null
                        }

                        {this.state.buttonSelected === "ChangeAccount" && this.state.accountsLoaded ?
                            <ChangeAccountModal openModal={this.state.openChangeAccountModal} 
                                accountList={this.accountInfo}
                                onClose={(accountObj) => this.closeChangeAccountModal(accountObj)} 
                            />
                        :
                            null
                        }

                        {this.state.buttonSelected === "DeleteEventTracker"  ?
                            <EventTrackerDeleteModal openModal={this.state.openEventTrackerDeleteModal} 
                                onClose={() => this.closeModals("EventTracker")} 
                            />
                        :
                            null
                        }
                  
                        {this.state.buttonSelected === "ArchiveGame" ?
                            <span>
                            {this.allGameDataLoaded  ?
                                <GameInfoArchiveModal openModal={this.state.openGameArchiveModal} 
                                    gameInfoArr={this.gameInfoArr}
                                    savingsArr={this.savingsArr}
                                    creditCardArr={this.creditCardArr}
                                    funStuffArr={this.funStuffArr}
                                    activityTrackerArr={this.activityTrackerArr}
                                    onClose={() => this.closeModals("ArchiveGame")} 
                                    processError = {(errorObj) =>  this.props.processError(errorObj)}
                                />
                            :
                                <span>
                                    <div className="text-center mt-3">
                                        <button className="btn btn-primary w-50 mt-6" 
                                            disabled={true}>
                                                Archive Old Games and Orphaned Records
                                        </button>
                                    </div>
                                    <div className="text-center mt-3">
                                        <WaitTimeSpinner/>
                                    </div>
                                </span>
                            }
                            </span>
                        :
                            null
                        }
                        </span>

                    :
                            <div className="text-center mt-5">
                                <button className="btn btn-primary w-50 mt-6" 
                                    onClick ={()=> this.sendEmailToAccount()}>
                                         Send Email to Account Owner
                                </button>
                            </div>
                    }
                    </div>
                    {this.state.updateTestDatasetLoaded && this.state.eventsLoaded &&
                        this.state.openMoodPointUpdate ?
                        <UpdateEventMoodPoints
                            openModal={this.state.openMoodPointUpdate}
                            updateDataset = {this.updateTestDataset}
                            eventList = {this.eventArr}
                            onClose={() => this.closeModals("UpdateEventMoodPoints")} 
                            processError = {(errorObj) =>  this.props.processError(errorObj)}
                        />
                    :
                        null    
                    }
                   
                </div>        
            </div>
       
        </>
    }

getAccountList(){
    const funcName = "loadAccountList";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {method:'GET',
         headers: this.state.headers,
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        this.accountInfo = output.body;
                        this.setState({accountsLoaded: true});
                        alert("Account List has been loaded!!");
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        })
  }

  loadAllGameData(){
    this.allGameDataLoaded = false;
    this.loadAllGames();
    this.loadAllCreditCardInfo();
    this.loadAllFunStuff();
    this.loadAllSavings();
    this.loadAllActivityTracker();
}

  loadAllGames(){
    const funcName = "loadAllGames";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {method:'GET',
         headers: this.state.headers,
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        this.gameInfoArr = output.body;
                        this.setState({gamesLoaded: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        })
  }
  
  loadAllCreditCardInfo(){
    const funcName = "loadAllCreditCard";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {method:'GET',
         headers: this.state.headers,
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        this.creditCardArr = output.body;
                        this.setState({creditCardLoaded: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        })
  }

  
  loadAllFunStuff(){
    const funcName = "loadAllFunStuff";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {method:'GET',
         headers: this.state.headers,
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        this.funStuffArr = output.body;
                        this.setState({funStuffLoaded: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        })
  }
  
  loadAllSavings(){
    const funcName = "loadAllSavings";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {method:'GET',
         headers: this.state.headers,
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        this.savingsArr = output.body;
                        this.setState({savingsLoaded: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        })
  }
  
  loadAllActivityTracker(){
    const funcName = "loadAllActivityTrackers";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {method:'GET',
         headers: this.state.headers,
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        this.activityTrackerArr = output.body;
                        this.setState({activityTrackerLoaded: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        })
  }

  saveContactUs() {
    let headers = {'Content-Type': 'application/json',
        'Authorization': {},
    };
    if (this.emailParms.userName !==  "NotSignedIn"){
        headers = this.state.headers;
    }
    let dateObj = getCurrentDateTime();
    let bodyObj = {
        fromName: this.emailParms.from_name,
        toName: this.emailParms.to_name,
        userName: this.emailParms.userName,
        subject: this.emailParms.subject,
        message: this.emailParms.message,
        replyTo: this.emailParms.reply_to,
        dateAdded: dateObj.date,
        timeAdded: dateObj.time
    };
    const funcName = "saveContactUsEmail";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {
        method:'POST',
        headers: headers,
        body: JSON.stringify(bodyObj)
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        alert("Update of contactUsEmail failed. Page will automatically refresh once you click OK.");
                        window.location.reload(false);
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message, errorObject: bodyObj };
                        this.props.processError(errorObj);
                    }
                });
            };
        });
}

loadUpdateTestDataset(){
    const funcName = "loadUpdateTestDataset";
    const urlParm = "";
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
        {method:'GET',
         headers: this.state.headers,
        })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode === 200){
                        this.updateTestDataset = output.body;
                        this.setState({updateTestDatasetLoaded: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
        })
  }
  
loadEventsForCategory(){
    const gamePhase = "GetAllPhases";
    const category = "job";
    const jobName = "allJobEvents";
    const funcName = "loadEvents";
    const urlParm = "?gamePhase=" + gamePhase + "&eventCategory=" + category +
        "&jobName=" + jobName;
    const uri = buildUrl(this.state.environment, funcName, urlParm);
    fetch(uri,
          {method:'GET', headers: this.state.headers})
          .then(response => {
            if (response.ok){
                response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        this.eventArr = JSON.parse(JSON.stringify(output.body));
                        this.setState({eventsLoaded: true});
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message};
                        this.props.processError(errorObj);
                    }
                });
            };
      });
}


}

export default OtherUpdates;