import { Component } from 'react';
import "./GameViews/gameViews.css";
import { withRouter } from "react-router";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { setHeadersForFetch } from '../../objects/CommonUse/set_headers_for_fetch';
import getCurrentDateTime from '../../objects/CommonUse/get_current_date_time';
import FunStuffDataset from './datasets/fun_stuff_dataset';
import ImageCard from '../shared-components/image_card';
import TwoColumnTableCard from './two_column_table_card';
import { calcMonthlyIncome } from '../../objects/CommonUse/calcMonthlyIncome';
import BackgroundDataset from './datasets/backgroundDataset';
import buildJokeCard from './objects/build_joke_card';
import ConfigFile from '../../datasets/config_file';
import { monthlyLoanAmt } from '../../objects/AssetsExpenses/loanAmtCalc_function';
import { calcNbrMonthsBtwnDates } from '../../objects/CommonUse/nbr_months_btwn_dates_calc';
import determineWhichFriendToDisplay from './objects/determine_which_friend_to_display';
import {buildUrl } from '../../objects/CommonUse/build_URL';
import Config from '../../config';
import setModalSize from '../../objects/CommonUse/set_modal_size';


class SetupFunStuffModal extends Component {
    constructor(props) {
        super(props);
        this.gameInfo = this.props.gameInfo();
        this.state = {
            headers: setHeadersForFetch(),
            updateEnabled: false,
            step: "",
            defaultClass: "font-weight-bold ",
            openFriendModal: false,
            funStuffArr: FunStuffDataset,
            optionIndxArr: [],
            funStuffOptionsArr: [],
            pictureArr: [],
            defaultClass: "medium-size-img ml-4 my-4 picture-border",
            selectedItem: null,
            currentItem: null,
            currentDate: getCurrentDateTime().date,
            conditionalBackgrounds: BackgroundDataset,
            loanParms: ConfigFile.gameBoardLoans,
            loanRequired: false,
            subProcess: "setupFunStuffModal",
            environment: Config.dataURL.currentEnv,
        }
        this.initializeFields();
    }

    initializeFields() {
        let feature = this.gameInfo.gameData.unlockedFeatures.find(elem => elem.featureName === "funStuff");
        for (let i = 0; i < this.state.funStuffArr.length; i++) {
            if (+feature.level >= +this.state.funStuffArr[i].level) {
                this.state.optionIndxArr.push(i);
            }
        }
        this.createPictureArrForType();
    }

    onClick(picturesList) {
        let buttonType = sessionStorage.getItem("funStuffButtonClicked");
        if (buttonType != undefined) {
            this.setUpdateType(buttonType, picturesList);
            sessionStorage.removeItem("funStuffButtonClicked");
        } else {
            this.processSelectedPicture(picturesList)
        }
    }

    setUpdateType(buttonSlctd, picturesList) {
        this.updateType = buttonSlctd;
        if (this.updateType == "decline") {
            this.saveFunStuffSelection();
        } else {
            if (this.updateType == "remove") {
                this.enterName = false;
            }
            this.processSelectedPicture(picturesList);
        }
    }

    processSelectedPicture(picturesList) {
        switch (picturesList.type) {
            case "Type":
                this.typeIndx = this.state.funStuffArr.findIndex(elem => elem.type === picturesList.pictureText);
                this.saveInfoForSelectedType();
                if (this.updateType == "remove" || this.updateType == "change") {
                    this.determineCurrentOptionItem();
                    if (this.updateType == "remove") {
                        this.processSelectedItem();
                    } else {
                        this.createPictureArrForOptions();
                    }
                } else {
                    this.createPictureArrForOptions();
                }
                break;
            case "Option":
                let optionsArr = this.state.funStuffArr[this.typeIndx].options;
                this.optionIndx = optionsArr.findIndex(elem => elem.category === picturesList.pictureText);
                this.createPictureArrForList();
                break;
            case "List":
                let listArr = this.state.funStuffArr[this.typeIndx].options[this.optionIndx].list;
                this.listIndx = listArr.findIndex(elem => elem.type === picturesList.pictureText);
                this.processSelectedItem();

        }
    }

    saveInfoForSelectedType() {
        this.inputType = this.state.funStuffArr[this.typeIndx].input;
        this.levelName = this.state.funStuffArr[this.typeIndx].type;
        this.levelNbr = this.state.funStuffArr[this.typeIndx].level;
        this.modalTitle = this.state.funStuffArr[this.typeIndx].type + " (Level " +
            this.state.funStuffArr[this.typeIndx].level + ")";
        this.expItem = this.state.funStuffArr[this.typeIndx].expItem;
        this.titleType = this.state.funStuffArr[this.typeIndx].titleType;
        this.loanType = this.state.funStuffArr[this.typeIndx].loanType;
    }


    determineCurrentOptionItem() {
        let funStuffItem = this.gameInfo.funStuffInfo.itemArr.find(elem => elem.levelName === this.levelName);
        this.state.currentItem = funStuffItem;
        this.optionIndx =
            (this.state.funStuffArr[this.typeIndx].options).findIndex(elem => elem.category == funStuffItem.category);
        let listArr = this.state.funStuffArr[this.typeIndx].options[this.optionIndx].list;
        this.listIndx = listArr.findIndex(elem => elem.type == funStuffItem.type);
        this.returnAmounts = listArr[this.listIndx].returnAmounts;
        
    }


    createPictureArrForType() {
        this.state.pictureArr = [];
        for (let i = 0; i < this.state.optionIndxArr.length; i++) {
            let arrIndx = this.state.optionIndxArr[i];
            this.determineRequiredButtons(this.state.funStuffArr[arrIndx].type, arrIndx)
            let pictureObj = {
                type: "Type", imageURL: "assets/" + this.state.funStuffArr[arrIndx].imageURL,
                disable: true, imageClass: this.state.defaultClass, pictureText: this.state.funStuffArr[arrIndx].type,
                buttonType: this.funStuffButtons
            };
            this.state.pictureArr.push(pictureObj);
        }
        this.state.step = "SelectType";
    }

    determineRequiredButtons(typeName) {
        let funStuffArr = [];
        if (this.gameInfo.funStuffInfo.itemArr != undefined) {
            funStuffArr = this.gameInfo.funStuffInfo.itemArr;
        }
        let funStuffIndx = funStuffArr.findIndex(elem => elem.levelName === typeName);
        if (funStuffIndx == -1) {
            this.funStuffButtons = "AddDecline";
        } else {
            this.previousItemType = funStuffArr[funStuffIndx].type;
            this.funStuffButtons = "ChangeRemove";

        }
    }

    createPictureArrForOptions() {
        let funStuffOptions = this.state.funStuffArr[this.typeIndx].options;
        this.state.pictureArr = [];
        if (funStuffOptions.length == 1) {
            if (this.state.step != "Back") {
                this.optionIndx = 0;
                this.createPictureArrForList();
            }
        } else {
            for (let i = 0; i < funStuffOptions.length; i++) {
                let pictureObj = {
                    type: "Option", imageURL: "assets/" + funStuffOptions[i].imageURL,
                    disable: false, imageClass: this.state.defaultClass, pictureText: funStuffOptions[i].category
                };
                this.state.pictureArr.push(pictureObj);

            }
            this.state.step = "SelectOption";
        }
    }

    createPictureArrForList() {
        this.state.pictureArr = [];
        this.newConditionName = this.state.funStuffArr[this.typeIndx].options[this.optionIndx].condName;
        this.categoryName = this.state.funStuffArr[this.typeIndx].options[this.optionIndx].category;
        let funStuffList = this.state.funStuffArr[this.typeIndx].options[this.optionIndx].list;
        if (funStuffList.length == 1) {
            if (this.state.step != "Back") {
                this.listIndx = 0;
                this.processSelectedItem();
                this.state.step = "ItemSelected"
            } else {
                this.createPicturesForOptions();
            }
        } else {
            for (let i = 0; i < funStuffList.length; i++) {
                let disable = false;
                let pictureClass = this.state.defaultClass;
                if (this.state.currentItem != null &&
                    funStuffList[i].type == this.state.currentItem.type) {
                    disable = true;
                    pictureClass = this.state.defaultClass + " disabled-picture";
                }
                let pictureObj = {
                    type: "List", imageURL: "assets/" + funStuffList[i].image,
                    disable: disable, imageClass: pictureClass, pictureText: funStuffList[i].type
                };
                this.state.pictureArr.push(pictureObj);
            }
            this.state.step = "SelectItem";
        }

    }

    processSelectedItem() {
        let listArr = this.state.funStuffArr[this.typeIndx].options[this.optionIndx].list;
        this.state.selectedItem = listArr[this.listIndx];
        this.setTitleLit();
        this.selectItemType = this.state.selectedItem.type;
        this.selectItemURL = "/assets/" + this.state.selectedItem.image;
        this.itemText = this.state.selectedItem.text;
        this.totalMoodPoints = 0;
        switch(this.updateType){
            case "add":
                this.totalMoodPoints = 70;
                break;
            case "change":
                this.totalMoodPoints = -70;
                break;
            case "remove":
                this.totalMoodPoints = -140;
                break;
        }
        this.determineOneTimeCosts();
        this.determineOngoingCosts();
        this.state.step = "ItemSelected";
    }

    determineOneTimeCosts() {
        let onetimeCosts = this.state.selectedItem.onetimeCosts;
        this.onetimeCostArr = [{ label: "Current Cash Amount", amount: +this.gameInfo.gameData.currentCash }];
        this.state.loanRequired = false;
        if (this.updateType == "add" || this.updateType == "change") {
            for (let i = 0; i < onetimeCosts.length; i++) {
                if (+onetimeCosts[i].amt < 10000) {
                    let costObj = { label: onetimeCosts[i].desc, amount: (-1 * onetimeCosts[i].amt), color: "red" };
                    this.onetimeCostArr.push(costObj);
                } else {
                    //  setup for getting a loan
                    this.state.loanRequired = true;
                    let downPaymentPct = this.state.loanParms.pctDownPayment;
                    let downPaymentAmt = (+onetimeCosts[i].amt * +downPaymentPct).toFixed(2);
                    let costObj = { label: "Downpayment for " + this.state.selectedItem.type, amount: (downPaymentAmt * -1),
                        color: "red" };
                    this.onetimeCostArr.push(costObj);
                    this.loanAmt = (+onetimeCosts[i].amt - +downPaymentAmt);
                }
            };
        }
        let costObj = {};
        this.loanOutstandingAmt = 0;
        if (this.updateType == "change" || this.updateType == "remove") {
            let textColor = "red";
            let onetimeAmt = 0;
            if (this.loanType != undefined &&
                this.loanType != "") {
                this.calculateCurrentLoanAmt();
                if (+this.loanOutstandingAmt > 0) {
                    costObj = {
                        label: "Pay Off Balance for Current Loan", amount: (-1 * this.loanOutstandingAmt),
                        color: "red"
                    };
                    this.onetimeCostArr.push(costObj);
                    onetimeAmt = +onetimeAmt - +this.loanOutstandingAmt;
                }
            }

            for (let i = 0; i < this.returnAmounts.length; i++) {
                if (this.returnAmounts[i].type == "income") {
                    textColor = "black";
                }
                if (this.returnAmounts[i].depPercent == undefined ||
                    +this.returnAmounts[i].depPercent == 0) {
                    onetimeAmt = +this.returnAmounts[i].amt;
                    if (this.returnAmounts[i].type != undefined &&  
                       this.returnAmounts[i].type != "income"){
                         onetimeAmt = +onetimeAmt * -1;
                    }                
                } else {
                    let totNbrMonths = calcNbrMonthsBtwnDates(this.state.currentItem.startDate, 
                        this.gameInfo.gameData.currentGameDate);
                    let nbrYears = (+totNbrMonths / 12).toFixed(2);
                    let reducedAmt = +this.returnAmounts[i].amt * +this.returnAmounts[i].depPercent * +nbrYears;
                    onetimeAmt = +this.returnAmounts[i].amt - reducedAmt;
                }
                costObj = { label: this.returnAmounts[i].desc, amount: onetimeAmt, color: textColor };
                this.onetimeCostArr.push(costObj);
           }
        }
        this.totalOneTimeAmt = 0
        // skipping first entry because that is the current cash and total needs to be only the difference amt
        for (let i=1; i<this.onetimeCostArr.length; i++){
              this.totalOneTimeAmt += this.onetimeCostArr[i].amount;
        }
        let totalOneTimeObj = {
            label: "Total Cash After Change",
            amount: +this.gameInfo.gameData.currentCash + +this.totalOneTimeAmt
        }
        this.onetimeCostArr.push(totalOneTimeObj);
    }

    calculateCurrentLoanAmt() {
        this.loanOutstandingAmt = 0;
        let loanTable = this.gameInfo.gameData.loanTable;
        for (let i = 0; i < loanTable.length; i++) {
            if (loanTable[i].loanType == this.loanType) {
                this.loanOutstandingAmt = +this.loanOutstandingAmt + +loanTable[i].loanAmt * +loanTable[i].loanNbrMonths;
                this.loanOngoingObj = { label: loanTable[i].loanDesc, amt: loanTable[i].loanAmt, color: "black" };
                this.state.loanRequired = true;
                break;
            }
        }
    }

    determineOngoingCosts() {
        let ongoingCosts = this.state.selectedItem.ongoingCosts;
        let incomeObj = calcMonthlyIncome(this.gameInfo.gameData.assetTable,
            this.gameInfo.gameData.expenseTable, this.gameInfo.gameData.loanTable);
        this.newLoanObj = {};
        this.newOngoingCostArr = [];
        this.ongoingCostArr = [{ label: "Current Monthly Net Income", amount: +incomeObj.income }];
     
        if (this.updateType == "add" || this.updateType == "change") {
            if (this.state.loanRequired == true) {
                this.calculateOngoingLoanAmt();
            }
            for (let i = 0; i < ongoingCosts.length; i++) {
                let label = ongoingCosts[i].desc + " (" + this.selectItemType + ")";
                let costObj = { label: label, amount: (-1 * ongoingCosts[i].amt), color: "red" }
                this.ongoingCostArr.push(costObj);
                costObj = JSON.parse(JSON.stringify(costObj));
                costObj.amount = ongoingCosts[i].amt;
                this.newOngoingCostArr.push(costObj);
            }
        }
        if (this.updateType == "change" || this.updateType == "remove") {
            let expenseTable = this.gameInfo.gameData.expenseTable;
            for (let i = 0; i < expenseTable.length; i++) {
                if (expenseTable[i].expItem == this.expItem && expenseTable[i].expNbrMonths != 0) {
                    let label = expenseTable[i].expDesc;
                    let costObj = { label: label, amount: expenseTable[i].expAmount };
                    this.ongoingCostArr.push(costObj);
                }
            }
        }
        this.totalMonthIncome = 0;
        for (let i=0; i<this.ongoingCostArr.length; i++){
             this.totalMonthIncome += +this.ongoingCostArr[i].amount;
        }
        let totalIncomeObj = { label: "Monthly Net Income After Change", amount: this.totalMonthIncome};
        this.ongoingCostArr.push(totalIncomeObj);
    }


    calculateOngoingLoanAmt() {
        let totalLoanAmt = +this.loanAmt - +this.loanOutstandingAmt;
        let intRate = this.state.loanParms.intRate;
        let loanTerm = this.state.loanParms.loanTerm;
        let loanDesc = "Loan for " + this.state.selectedItem.type;
        let monthLoanAmt = monthlyLoanAmt(this.loanAmt, intRate, loanTerm);
        let loanNbrMonths = loanTerm * 12;
        this.newLoanObj = {
            loanType: this.loanType, loanDesc: loanDesc,
            loanAmt: monthLoanAmt, loanStartDate: this.gameInfo.gameData.currentGameDate,
            loanNbrMonths: loanNbrMonths, loanTotAmt: totalLoanAmt,
            loanIntRate: intRate, loanTerm: loanTerm
        };
        let costObj = { label: loanDesc, amount: (monthLoanAmt * -1), color: "red" };
        this.ongoingCostArr.push(costObj);
        this.totalMonthIncome -= +monthLoanAmt;
    }

    setTitleLit() {
        switch (this.updateType) {
            case "add":
                if (this.titleType == "animal") {
                    this.titleUpdateLit = "- Adopt a " + this.categoryName;
                } else {
                    this.titleUpdateLit = "- Purchase a " + this.categoryName;
                }
                break;
            case "change":
                if (this.titleType == "animal") {
                    this.titleUpdateLit = "- Surrender your " + this.state.currentItem.category + " and Adopt a new " + this.categoryName;
                } else {
                    this.titleUpdateLit = "- Sell your current " + + this.state.currentItem.category + " and Purchase a new " + this.categoryName;
                }
                break;
            case "remove":
                if (this.titleType == "animal") {
                    this.titleUpdateLit = "- Surrender your " + this.state.currentItem.category;
                } else {
                    this.titleUpdateLit = "- Sell your " + this.state.currentItem.category;
                }
                break;
        }
    }

    backButton() {
        switch (this.state.step) {
            case "SelectType":
                this.props.onClose("back");
                break;
            case "SelectOption":
                this.state.step = "Back";
                this.createPictureArrForType();
                break;
            case "SelectItem":
                this.state.step = "Back";
                this.createPictureArrForOptions();
                break;
            case "ItemSelected":
                this.state.step = "Back";
                this.createPictureArrForList();
                break;
        }
        // if the next step is still back after this, then exit to the unlocked features selection
        if (this.state.step == "Back") {
            this.props.history.push('/game-board');
        }
    }

    displayFriendModal() {
        this.state.openFriendModal = true;
        this.askAFriendInfo = { topic: "setupSavings" }
    }

    closeFriendModal() {
        this.state.openFriendModal = false;
    }

    render() {
        if (this.state.step == "SelectType" || this.state.step == "SelectOption" || 
            this.state.step == "SelectItem") {
            this.pictureDisplay = this.state.pictureArr.map((picturesList, key) =>
                <ImageCard imageInfo={picturesList} key={key}
                    disabled={this.state.disabled}
                    onClick={() => this.onClick(picturesList)}
                />
            );
        }
        if (this.state.step == "ItemSelected") {
            this.ongoingAmts = this.ongoingCostArr.map((cashAmts, key) =>
                <TwoColumnTableCard key={key} infoType="label-amount" outInfo={cashAmts}
                    className="py-0 px-4 text-right" />
            );
            this.onetimeAmts = this.onetimeCostArr.map((cashAmts, key) =>
                <TwoColumnTableCard key={key} infoType="label-amount" outInfo={cashAmts}
                    className="py-0 px-4 text-right" />
            );
        }
        this.gutters = setModalSize(this.props.screenSize);
       
        return <>

            <Modal show={this.props.show} onHide={() => this.props.onClose("none")} size="lg" scrollable="true"
                dialogClassName={this.gutters} animation={false} >
                <Modal.Header className="backgroundTopBottom" closeButton>
                    <Modal.Title className="text-center">Select Fun Stuff&nbsp; {this.titleUpdateLit}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="mx-4 my-4">
                        {this.state.step == "SelectType" || this.state.step == "SelectOption" || this.state.step == "SelectItem" ?
                            <div>
                                {this.state.step == "SelectType" ?
                                    <div>Click on the button below the picture to select an item.</div>
                                    :
                                    <div>Click on the picture to select the fun stuff item that you would like to get.</div>
                                }
                                <div>
                                    {this.pictureDisplay}
                                </div>
                            </div>
                            :
                            null
                        }
                    </div>

                    {this.state.step == "ItemSelected" ?
                        <span>
                            {this.updateType != "remove" ?
                                <div>{this.itemText}</div>
                                :
                                null
                            }
                            {this.inputType == "name" && this.updateType != "remove" ?
                                <div className="mt-2">
                                    <label className="font-weight-bold" htmlFor="itemName">
                                        Pick a Name:&nbsp;&nbsp;</label>
                                    <input type="text" className="col-med" id="itemName" name="itemName"></input>
                                    <span className="text-danger">
                                        &nbsp;{this.nameError}</span>
                                </div>
                                :
                                null
                            }
                             <div className="text-left font-weight-bold ml-4 mb-2">
                                    Total Mood Point Change: {this.totalMoodPoints}
                                </div>
                           <table>
                                <tbody>
                                <tr className="mt-5" >
                                    <td className="text-center-font-weight-bold" colSpan='2'>Ongoing (Monthly) Costs</td>
                                </tr>
                                {this.ongoingAmts}
                                <tr className="mt-5">
                                    <td className="text-center font-weight-bold"  colSpan='2'>One-Time Costs</td>
                                </tr>
                                {this.onetimeAmts}
                                </tbody>
                            </table>
                            <img
                                src={this.selectItemURL}
                                className="funstuff-item-picture picture-border picture-container" alt="image" />
                            <div className="funstuff-item-text-bottom font-weight-bold">
                                {this.selectItemType}
                            </div>
                        </span>
                        :
                        null
                    }


                </Modal.Body>
                <Modal.Footer className="backgroundTopBottom">
                    <div className={this.msgClass}>{this.message}</div>
                    {this.state.step == "ItemSelected" ?
                        <span>
                            <Button className="mr-2" variant="secondary" onClick={() => this.editFormFields()} >
                                Submit
                            </Button>

                        </span>
                        :
                        null
                    }
                    <Button variant="dark" onClick={() => this.backButton()}>
                        Back
                    </Button>
                    <Button variant="primary" onClick={() => this.props.onClose("none")}>
                        Close
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    }

    editFormFields() {
        this.validData = true;
        if (this.updateType != "remove") {
            if (this.inputType == "name") {
                this.itemName = document.getElementById("itemName").value;
                this.nameError = "";
                if (this.itemName == "") {
                    this.validData = false;
                    this.nameError = "Name cannot be blank.";
                }
            }
        }
        if (this.validData == true) {
            this.saveFunStuffSelection();
        }
        // create new router to update unlocked, icon, condition, cash, mood pts, expense, 
        // add mood point update to the unlocked features update (make chgs to other processes that use this router)
        // consider/add cost of getting rid of an item (both for change and remove)
    }

    saveFunStuffSelection() {
        this.updatePlayerGameInfo();
        if (this.updateType != "decline") {

            this.updateFunStuffData();
            this.updatePictureInfo();
        }
        this.props.onClose("complete");
    }

    updatePlayerGameInfo() {
        let cashAmt = this.gameInfo.gameData.currentCash;
        let moodPoints = this.gameInfo.gameData.currentMoodPoints;
        if (this.updateType != "decline") {
            cashAmt = (+cashAmt + +this.totalOneTimeAmt);
            moodPoints = (+moodPoints + +this.totalMoodPoints);
            this.updateConditionTable();
            this.createNewExpenseTableObject();
            this.newLoanTable = this.gameInfo.gameData.loanTable;
            if (this.state.loanRequired == true) {
                this.createNewLoanTableObject();
            }
        }
        if (this.updateType == "change" || this.updateType == "remove") {
            // remove any cards in cardList (or current card) that were for previous funStuff item (they are no longer valid)
            if (this.gameInfo.gamePhase == "1") {
                this.checkGameCardHand();
            }
            this.checkCurrentCard();
        }
        this.updateUnlockedFeatures();

        this.gameInfo.gameData.currentCash = cashAmt;
        this.gameInfo.gameData.currentMoodPoints = moodPoints;
        this.gameInfo.gameData.expenseTable = this.newExpenseTable;
        this.gameInfo.gameData.loanTable = this.newLoanTable;
        
        this.props.setGameInfo(this.gameInfo.gameData, 'gameData');
        this.bodyObj = {
            "currentCash": this.gameInfo.gameData.currentCash,
            "currentMoodPoints": this.gameInfo.gameData.currentMoodPoints,
            "expenseTable": this.gameInfo.gameData.expenseTable,
            "loanTable": this.gameInfo.gameData.loanTable,
            "conditionsTable": this.gameInfo.gameData.conditionsTable,
            "unlockedFeatures": this.gameInfo.gameData.unlockedFeatures,
            "iconTable": this.gameInfo.gameData.iconTable,
        };
        const funcName = "updateGameInfoGeneral";
        const urlParm = "";
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {
                method: 'PUT', headers: this.state.headers,
                body: JSON.stringify(this.bodyObj)
            })
        .then(response => {
            if (response.ok){
                response.json().then(data => {
                const output = JSON.parse(data);
                const statusCode = output.statusCode;
                if (statusCode == 200){
                    // "update of game general Info was successful";
                }else{
                    const errorObj={function: funcName, subProcess: this.state.subProcess, 
                        status: statusCode, message: output.body.message, errorObject: this.bodyObj};
                    this.props.processError(errorObj);
                }
            });
            }
        });
    
    }
    
    updateConditionTable() {
        if (this.updateType == "remove" || this.updateType == "change") {
            let maxIndx = this.gameInfo.gameData.conditionsTable.length - 1;
            for (let i = maxIndx; i >= 0; i--) {
                if (this.gameInfo.gameData.conditionsTable[i].condName == this.state.currentItem.condName) {
                    this.gameInfo.gameData.conditionsTable.splice(i, 1);
                    break;
                }
            }
        }
        if (this.updateType == "add" || this.updateType == "change") {
            let eventCondition = 
                this.gameInfo.eventConditions.find(elem => elem.condName == this.state.selectedItem.condName);    
            let conditionObj = { condName: this.state.selectedItem.condName,
                conditionClass: eventCondition.conditionClass, duration: -1 };
            this.gameInfo.gameData.conditionsTable.push(conditionObj);
        }
    }

    createNewExpenseTableObject() {
        var i;
        this.newExpenseTable = this.gameInfo.gameData.expenseTable;
        for (i = 0; i < this.newExpenseTable.length; i++) {
            if (this.newExpenseTable[i].expItem == this.expItem &&
                +this.newExpenseTable[i].expNbrMonths != 0) {
                this.newExpenseTable[i].expNbrMonths = 0;
            }

        }
        for (let i = 0; i < this.newOngoingCostArr.length; i++) {
            this.newExpenseObj = {
                expItem: this.expItem, expDesc: this.newOngoingCostArr[i].label,
                expAmount: this.newOngoingCostArr[i].amount, expStartDate: this.state.currentDate, expNbrMonths: "-1",
                chargeCreditCard: true
            };
            this.newExpenseTable.push(this.newExpenseObj);
        }
    }

    createNewLoanTableObject() {
        for (let i = 0; i < this.newLoanTable.length; i++) {
            if (this.newLoanTable[i].loanType == this.loanType &&
                +this.newLoanTable[i].loanNbrMonths != 0) {
                this.newLoanTable[i].loanNbrMonths = 0;
            }
        }
        this.newLoanTable.push(this.newLoanObj);
    }

    checkGameCardHand() {
        let cardListChanged = false;
        for (let i = 0; i < this.gameInfo.cardList.length; i++) {
            if (this.gameInfo.cardList[i].conditionArr != undefined &&
                this.gameInfo.cardList[i].conditionArr.includes(this.state.currentItem.condName)) {
                    let jokeCard = buildJokeCard(...this.gameInfo.gamePhase);
                    this.gameInfo.cardList[i] = jokeCard;
                    cardListChanged = true;
            }
            if (cardListChanged == true){
                this.props.setGameInfo(this.gameInfo.cardList, "cardList");
            }
        }
       
    }

    checkCurrentCard() {
        if (this.gameInfo.currentCard.conditionArr != undefined){
            if (this.gameInfo.currentCard.conditionArr.includes(this.state.currentItem.condName)){
                let jokeCard = buildJokeCard(...this.gameInfo.gamePhase);
                this.gameInfo.currentCard = jokeCard;
                this.gameInfo.currentCard = determineWhichFriendToDisplay(this.gameInfo.gameData.friendList, 
                    this.gameInfo.friendInfo, this.gameInfo.currentCard, this.gameInfo.gameData.currentGameDate);
                this.props.setGameInfo(this.gameInfo.currentCard, "currentCard");
            }
            this.props.setGameInfo(this.gameInfo.pictureInfo.livingRoomImg, "backgroundURL");
        }
    }

    updateUnlockedFeatures(){
        this.featureIndx = this.gameInfo.gameData.unlockedFeatures.findIndex(elem => elem.featureName === "funStuff");
        let unlockedFeaturesObj = this.gameInfo.gameData.unlockedFeatures[this.featureIndx];
        if (+this.levelNbr < unlockedFeaturesObj.level){
             // if updating a level prior to current level, then no change should be made to unlocked features or icons!!!
        }else{
            let activeInactive = "none";
            if (+this.levelNbr == unlockedFeaturesObj.level){
                if (this.updateType == "add" || this.updateType == "change") {
                    activeInactive = "active";
                    this.updateIconTable();
                }
                if (this.updateType == "remove"){
                    activeInactive = "inactive"
                }
            }
            unlockedFeaturesObj.setup = activeInactive;
            this.gameInfo.gameData.unlockedFeatures[this.featureIndx] = unlockedFeaturesObj;
        }
    }

    updateIconTable(){
        let iconTable = this.gameInfo.gameData.iconTable;
        let startIdx = iconTable.length - 1;
        for (let i = startIdx; i >= 0; i--) {
            if (iconTable[i].iconName == "funStuffUnlocked" ||
                iconTable[i].iconName == "funStuffReminder" ||
                iconTable[i].iconName == "funStuffNewLevel") {
                iconTable.splice(i, 1);
                break;
            }
        }
        this.gameInfo.gameData.iconTable = iconTable;
    }

    updateFunStuffData() {
        if (this.gameInfo.funStuffInfo.gameID == undefined) {
            this.newFunStuffEntry = true;
        } else {
            this.newFunStuffEntry = false;
        }
        this.setBodyObj();
        this.updateFunStuff();

    }

    setBodyObj() {
        var dateCreated;
        if (this.newFunStuffEntry == true) {
            dateCreated = this.state.currentDate;
        } else {
            dateCreated = this.gameInfo.funStuffInfo.dateCreated;
        }

        if (this.updateType == "change" || this.updateType == "remove") {
            this.funStuffItem = "";
            let maxIndx = this.gameInfo.funStuffInfo.itemArr.length - 1;
            for (let i = maxIndx; i >= 0; i--) {
                if (this.gameInfo.funStuffInfo.itemArr[i].levelName == this.levelName) {
                    this.gameInfo.funStuffInfo.itemArr.splice(i, 1);
                }
            }
        }
        this.gameID = this.gameInfo.gameData._id;
        if (this.updateType == "add" || this.updateType == "change") {
            if (this.gameInfo.funStuffInfo.itemArr == undefined) {
                let funStuffObj = {
                    gameID: this.gameID,
                    itemArr: [],
                    dateAdded: dateCreated,
                    dateUpdated: ""
                }
                this.gameInfo.funStuffInfo = funStuffObj;
            }
            this.funStuffItem = {
                levelName: this.levelName,
                category: this.categoryName,
                type: this.state.selectedItem.type,
                name: this.itemName,
                condName: this.state.selectedItem.condName,
                pictureLabel: this.state.selectedItem.label,
                pictureURL: this.state.selectedItem.image,
                backgroundURL: this.state.selectedItem.backgroundURL,
                pictureClass: this.state.selectedItem.imageClass,
                startDate: this.gameInfo.gameData.currentGameDate,
            }
            this.gameInfo.funStuffInfo.itemArr.push(this.funStuffItem);
        }
        this.gameInfo.funStuffInfo.dateUpdated = this.state.currentDate;
        this.props.setGameInfo(this.gameInfo.funStuffInfo, 'funStuffInfo');
    }

    updateFunStuff() {
        const funcName = "updateFunStuff";
        const urlParm = "?gameID=" + this.gameID;
        const uri = buildUrl(this.state.environment, funcName, urlParm);
        fetch (uri,  
            {
                method: 'PUT', headers: this.state.headers,
                body: JSON.stringify(this.gameInfo.funStuffInfo)
            })
            .then(response => {
                if (response.ok){
                    response.json().then(data => {
                    const output = JSON.parse(data);
                    const statusCode = output.statusCode;
                    if (statusCode == 200){
                        // "update fun stuff was successful"
                    }else{
                        const errorObj={function: funcName, subProcess: this.state.subProcess, 
                            status: statusCode, message: output.body.message, errorObject: this.gameInfo.funStuffInfo};
                        this.props.processError(errorObj);
                    }
                });
            }
            });
    }

    updatePictureInfo() {
        this.conditionalObject = {};
        let pictureLabel = "funStuff-" + this.state.selectedItem.label;
        let URLValue = "./assets" + this.state.selectedItem.backgroundURL;
        if (this.gameInfo.pictureInfo.pictureLabel == undefined){
              let pictureObj = {[pictureLabel]: URLValue};
              this.gameInfo.pictureInfo = { ...this.gameInfo.pictureInfo, ...pictureObj};
              this.gameInfo.pictureInfo.funStuffInfo = true;
        }else{
             this.gameInfo.pictureInfo.pictureLabel = URLValue;
        }
        this.props.setGameInfo(this.gameInfo.pictureInfo, 'pictureInfo');
    }

}

export default withRouter(SetupFunStuffModal);